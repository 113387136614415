<template lang="pug">
q-page.full-height
  q-scroll-area(style="height: 100%")
    .flex.flex-center.full-height.full-width
      c_event_video_101(
        v-if="siteId && eventId",
        :siteId="siteId",
        :eventId="eventId",
        style="min-width: 800px; width: 800px"
      )
</template>

<style>
.q-scrollarea__content {
  height: 100%;
}
</style>

<script>
export default {
  data: function () {
    return {
      siteId: "",
      eventId: "",
    };
  },
  watch: {
    "$route.query": function () {
      this.handleQuery();
    },
  },
  mounted: function () {
    this.handleQuery();
  },
  methods: {
    handleQuery: function () {
      this.siteId = this.$route.query["site"];
      this.eventId = this.$route.query["event"];
    },
  },
};
</script>
