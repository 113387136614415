<template lang="pug">
template(v-if="uxMonitoringReady")
  q-layout.full-height(view="lHr LpR lFr")
    Header.bg-blue-10
    Drawer

    q-page-container.full-height
      Page.bg-blue-10
</template>

<style>
</style>

<script>
import Header from "./2header.vue";
import Drawer from "./2drawer.vue";
import Page from "./3page.vue";
export default {
  components: {
    Header,
    Drawer,
    Page,
  },
  computed: {
    uxMonitoringReady: function () {
      return this.$store.getters.uxMonitoringReady;
    },
  },
  mounted: function () {
    console.debug("✅ 3routes.videoai.1index.vue loaded");
  },
};
</script>
