<template lang="pug">
q-header.row.no-wrap(height-hint="66")
  q-toolbar
    q-btn(@click="toggleRightDrawer", flat, round, dense, icon="menu")
    q-space
    template(v-if="userMeDealer?.provider_picture")
      q-btn(@click="openProfileDialog", flat, round, dense)
        q-avatar(size="30px")
          img(:src="userMeDealer?.provider_picture")
    template(v-else)
      q-btn(@click="openProfileDialog", flat, round, dense, icon="person")
</template>

<style>
</style>

<script>
export default {
  computed: {
    userMeDealer: function () {
      return this.$store.getters.userMeDealer;
    },
    uxMonitoringSoundOn: function () {
      return this.$store.getters.uxMonitoringSoundOn;
    },
    uxMonitoringRightDrawerOpen: function () {
      return this.$store.getters.uxMonitoringRightDrawerOpen;
    },
  },
  methods: {
    openProfileDialog: function () {
      this.$c.dialog("profile");
    },
    toggleRightDrawer: function () {
      this.$store.commit("TOGGLE_UX_MONITORING_RIGHT_DRAWER");
    },
  },
};
</script>
