<template lang="pug">
q-drawer.bg-grey-1(
  :model-value="uxMonitoringRightDrawerOpen",
  show-if-above,
  side="left",
  behavior="desktop",
  :width=320,
  style="overflow-x: hidden"
)
  q-scroll-area(style="height: 100%", visible, ref="c_scroll_area")
    q-scroll-observer(@scroll="onScroll")
    c_list_dealer_sites_videoai(v-if="view === 'dealer'")
    c_list_site_events_videoai(
      v-else-if="view === 'site'",
      :hitTop="hitTop",
      :hitBottom="hitBottom"
    )
</template>

<style>
</style>

<script>
export default {
  computed: {
    uxMonitoringRightDrawerOpen: function () {
      return this.$store.getters.uxMonitoringRightDrawerOpen;
    },
  },
  data: function () {
    return {
      siteId: "",
      view: "dealer",
      hitTop: 0,
      hitBottom: 0,
    };
  },
  watch: {
    "$route.query": function () {
      this.handleQuery();
    },
  },
  mounted: function () {
    this.handleQuery();
  },
  methods: {
    onScroll: function (e) {
      const c_scroll_area = this.$refs.c_scroll_area;
      const scrollTop = e?.position?.top;
      const clientHeight = c_scroll_area?.getScrollTarget()?.clientHeight;
      const scrollHeight = c_scroll_area?.getScrollTarget()?.scrollHeight;
      switch (e?.direction) {
        case "up":
          if (scrollTop > 0) return;
          return this.hitTop++;
        case "down":
          if (scrollTop + clientHeight + 5 < scrollHeight) return;
          return this.hitBottom++;
      }
    },
    //
    handleQuery: function () {
      this.siteId = this.$route.query["site"];
      if (this.siteId) {
        this.view = "site";
        return;
      }
      this.view = "dealer";
    },
  },
};
</script>
